<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template slot="botaoDepoisAcaoTitulo">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="v-btn--simple"
            icon
            v-bind="attrs"
            @click="mostraFiltro = !mostraFiltro"
            v-on="on"
          >
            <v-icon>{{ mostraFiltro? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t( mostraFiltro ? 'padrao_esconder_filtro':'padrao_exibir_filtro') }}</span>
      </v-tooltip>
    </template>
    <template slot="antesDaDataTable">
      <v-row v-show="mostraFiltro">
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
          />
        </v-col>
        <v-col
          :cols="4"
        >
          <v-autocomplete
            v-model="autoCompleteDepositoModel"
            :readonly="!empresaClienteModelId"
            :items="autoCompleteDepositoItens"
            :loading="autoCompleteDepositoLoading"
            :search-input.sync="autoCompleteDepositoCampoBusca"
            :filter="filterDepositoAc"
            :label="$t('EstoqueList.deposito')"
            hide-no-data
            item-text="nome"
            item-value="id"
            :placeholder="$t('padrao_digite_para_buscar')"
            prepend-icon="mdi-database-search"
            return-object
            clearable
          />
        </v-col>
        <v-col
          :cols="5"
        >
          <v-autocomplete
            v-model="autoCompleteItemModel"
            :readonly="!empresaClienteModelId"
            :items="autoCompleteItemItens"
            :loading="autoCompleteItemLoading"
            :search-input.sync="autoCompleteItemCampoBusca"
            :filter="filterItemAc"
            :label="$t('EstoqueList.item')"
            hide-no-data
            item-text="nomeItem"
            item-value="id"
            :placeholder="$t('padrao_digite_para_buscar')"
            prepend-icon="mdi-database-search"
            return-object
            clearable
          />
        </v-col>
        
        <v-col
          cols="2"
        >
          <v-btn
            class="v-btn--simple"
            color="primary"
            outlined
            :icon="$vuetify.breakpoint.smAndDown"
            @click="buscar"
          >
            <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t('padrao_pesquisar') }}</span>
            <v-icon>{{ 'mdi-magnify' }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <hr v-show="mostraFiltro">
    </template>
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td v-if="!$vuetify.breakpoint.smAndDown">
          {{ item.item.deposito }}
        </td>
        <td v-if="!$vuetify.breakpoint.smAndDown">
          {{ item.item.item }}
        </td>
        <td v-if="$vuetify.breakpoint.smAndDown">
          <table>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('EstoqueList.deposito') }}
                </span>
              </td><td>
                {{ item.item.deposito }}
              </td>
            </tr>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('EstoqueList.item') }}
                </span>
              </td><td>{{ item.item.item }}</td>
            </tr>
          </table>
        </td>
        <td
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <span class="ml-4">{{ parseFloat(item.item.quantidadeEstoque) }}</span>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="v-btn--simple"
                icon
                color="primary"
                @click="verHistorico(item.item.idDeposito, item.item.idItem)"
                v-on="on"
              >
                <v-icon>mdi-file-table-box-multiple-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('EstoqueList.ver_historico') }}</span>
          </v-tooltip>
        </td>
        <td v-if="$vuetify.breakpoint.smAndDown">
          <table>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('EstoqueList.quantidadeEstoque') }}
                </span>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="v-btn--simple"
                      icon
                      color="primary"
                      @click="verHistorico(item.item.idDeposito, item.item.idItem)"
                      v-on="on"
                    >
                      <v-icon>mdi-file-table-box-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('EstoqueList.ver_historico') }}</span>
                </v-tooltip>
              </td><td>
                {{ parseFloat(item.item.quantidadeEstoque) }}
              </td>
            </tr>
            <tr>
              <td>
                <span class="pr-2 bold">
                  {{ $t('EstoqueList.usuario') }}
                </span>
              </td><td>
                {{ item.item.usuario }}
              </td>
            </tr>
          </table>
        </td>
        <td v-if="!$vuetify.breakpoint.smAndDown">
          {{ item.item.usuario }}
        </td>
        <td v-if="!idEmpresaCliente || idEmpresaCliente < 0">
          {{ item.item.empresaCliente }}
        </td>
      </tr>
    </template>
  </crud-list>
</template>

<script>

  import Servico from '@/services/EstoqueService'
  import ItemService from '@/services/ItemService'
  import DepositosService from '@/services/DepositosService'
  import { mapState } from 'vuex'
  import colunaEmpresaCliente from '@/utils/EmpresaClienteUtils'
  import EmpresaClienteAutoComplete from "@/views/EmpresaClienteAutoComplete"

  export default {
    components:{
      EmpresaClienteAutoComplete
    },
    data: () => ({
      totalRegistros: 0,
      pagination: {},
      items: [],
      loading: false,
      itemRemover: {},
      mostraFiltro: true,
      empresaClienteModel: null,
      empresaClienteModelId: null,
      autoCompleteItemModel: null,
      autoCompleteItemItens: [],
      autoCompleteItemLoading: false,
      autoCompleteItemCampoBusca: '',
      autoCompleteDepositoModel: null,
      autoCompleteDepositoItens: [],
      autoCompleteDepositoLoading: false,
      autoCompleteDepositoCampoBusca: '',
      depositoModelId: null,
      itemModelId: null,
      filtro: {
        idItem: null,
        idDeposito: null,
        idEmpresaCliente: null
      },
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPesquisa: function () {
        return this.$i18n.t('EstoqueList.tituloPesquisa')
      },
      headers: function () {
        const retorno = [
          {
            text: this.$i18n.t('EstoqueList.deposito'),
            value: 'deposito',
            sortable: false
          },
          {
            text: this.$i18n.t('EstoqueList.item'),
            value: 'item',
            sortable: false
          },
          {
            text: this.$i18n.t('EstoqueList.quantidadeEstoque'),
            value: 'quantidadeEstoque',
            sortable: false
          },
          {
            text: this.$i18n.t('EstoqueList.usuario'),
            value: 'usuario',
            sortable: false
          }
        ]

        if (!this.idEmpresaCliente || this.idEmpresaCliente < 0) {
          retorno.push(colunaEmpresaCliente(this.$i18n.t('EmpresaClientesForm.tituloPagina')))
        }

        return retorno
      }
    },
    watch: {
      idEmpresaCliente (val) {
        if(val !== 0){
          this.buscar()
        }
      },
      empresaClienteModel(val) {
        if (val === null || !val) {
          this.empresaClienteModelId = null
          this.filtro.idEmpresaCliente = null
          this.autoCompleteItemModel= null
          this.autoCompleteItemItens= []
          this.autoCompleteItemLoading= false
          this.autoCompleteItemCampoBusca= ''
          this.autoCompleteDepositoModel= null
          this.autoCompleteDepositoItens= []
          this.autoCompleteDepositoLoading= false
          this.autoCompleteDepositoCampoBusca= ''
        } else {
          this.empresaClienteModelId = val.id
          this.filtro.idEmpresaCliente = val.id
        }
      },
      autoCompleteItemModel(val) {
        if (val === null || !val) {
          this.itemModelId = null
          this.filtro.idItem = null
        } else {
          this.itemModelId = val.id
          this.filtro.idItem = val.id
        }
      },
      autoCompleteDepositoModel(val) {
        if (val === null || !val) {
          this.depositoModelId = null
          this.filtro.idDeposito = null
        } else {
          this.depositoModelId = val.id
          this.filtro.idDeposito = val.id
        }
      },
      autoCompleteItemCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaItem(val)
      },
      autoCompleteDepositoCampoBusca (val) {
        if (!val) {
          return
        }
        if (val.length < 3) {
          return
        }
        this.buscaDeposito(val)
      },
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)
      this.$root._events.pesquisa = []
      // this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    methods: {
      buscaDeposito (param) {
        this.autoCompleteDepositoLoading = true
        DepositosService.buscaDepositosPorParam(
          param,
          this.filtro.idEmpresaCliente
        )
          .then((depositos) => {
            this.autoCompleteDepositoItens = depositos.data
          })
          .catch((error) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca dos Depositos ' + error
            })
          })
          .finally(() => {
            this.autoCompleteDepositoLoading = false
          })
      },
      filterDepositoAc (item, queryText, itemText) {
        return true
      },
      buscaItem (param) {
        this.autoCompleteItemLoading = true
        ItemService.findByParamAutoComplete(
          param,
          this.filtro.idEmpresaCliente
        )
          .then((itens) => {
            this.autoCompleteItemItens = itens.data
          })
          .catch((error) => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro na busca dos Itens ' + error
            })
          })
          .finally(() => {
            this.autoCompleteItemLoading = false
          })
      },
      filterItemAc (item, queryText, itemText) {
        return true
      },
      efetuarPesquisa (pesquisa) {
        this.loading = true
        const param = {
          nome: pesquisa,
          idEmpresaCliente: this.idEmpresaCliente
        }
        Servico.findByParam(param, this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          path: '/histmovestForm/estoque'
        })
      },
      verHistorico(idDeposito, idItem) {
        this.$router.push({
          path: `/histmovest/${idDeposito}/${idItem}`
        })
      },
      buscar () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }
        this.loading = true

        this.filtro.idEmpresaCliente =
          this.empresaClienteModelId || this.idEmpresaCliente
        
        this.filtro.idDeposito = this.depositoModelId
        this.filtro.idItem = this.itemModelId

        Servico.findByItemDeposito(this.filtro, this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style>
.nome-bold {
  font-weight: bold;
}
</style>
